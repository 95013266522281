import { lazy, LazyExoticComponent } from 'react'
import mixpanel from 'mixpanel-browser'

import renderComponent from './helpers/renderHelpers'

mixpanel.init(
  process.env.MIXPANEL_ID as string,
  process.env.ENVIRONMENT === 'production'
    ? { api_host: process.env.MIXPANEL_HOST }
    : undefined,
)

type ComponentTypes =
  | 'badge'
  | 'banner'
  | 'chip'
  | 'npsDialog'
  | 'paymentsButton'
  | 'paymentsDialog'
  | 'cardFormButton'
  | 'cardFormDialog'
  | 'tagline'
  | 'cartTagline'
  | 'lmnDialog'
  | 'lmnButton'
  | 'orderSuccess'
  | 'standaloneLmn'
  | 'displayError'

const COMPONENTS = {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  badge: lazy(
    async () => await import(/* webpackChunkName: "Badge" */ './components/Badge/Badge'),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  banner: lazy(
    async () => await import(/* webpackChunkName: "Banner" */ './components/Banner/Banner'),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  chip: lazy(
    async () => await import(/* webpackChunkName: "Chip" */ './components/Chip/Chip'),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  lmnButton: lazy(
    async () => await import(
      /* webpackChunkName: "LmnDialog" */ './components/Button/LmnButton'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  lmnDialog: lazy(
    async () => await import(
      /* webpackChunkName: "LmnDialog" */ './components/Dialogs/Lmn/LmnDialog'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  npsDialog: lazy(
    async () => await import(
      /* webpackChunkName: "NpsDialog" */ './components/Dialogs/Nps/NpsDialog'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  paymentsButton: lazy(
    async () => await import(
      /* webpackChunkName: "PaymentsButton" */ './components/Button/PaymentsButton'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  paymentsDialog: lazy(
    async () => await import(
      /* webpackChunkName: "PaymentsDialog" */ './components/Dialogs/Payments/PaymentsDialog'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cardFormButton: lazy(
    async () => await import(
      /* webpackChunkName: "cardFormButton" */ './components/Button/OfflineOrderButton'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cardFormDialog: lazy(
    async () => await import(
      /* webpackChunkName: "cardFormDialog" */ './components/Dialogs/OfflineOrders/OfflineOrderDialog'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  tagline: lazy(
    async () => await import(/* webpackChunkName: "Tagline" */ './components/Tagline/Tagline'),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  cartTagline: lazy(
    async () => await import(
      /* webpackChunkName: "CartTagline" */ './components/CartTagline/CartTagline'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  thankYou: lazy(
    async () => await import(
      /* webpackChunkName: "ThankYou" */ './components/Dialogs/ThankYou/ThankYouDialog'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  orderSuccess: lazy(
    async () => await import(
      /* webpackChunkName: "OrderSuccess" */ './components/Dialogs/OrderSuccess/OrderSucess'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  standaloneLmn: lazy(
    async () => await import(
      /* webpackChunkName: "standaloneLmn" */ './components/Dialogs/Lmn/StandaloneLmn'
    ),
  ),
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  displayError: lazy(
    async () => await import(
      /* webpackChunkName: "standaloneLmn" */ './components/Dialogs/Payments/common/DisplayError'
    ),
  ),
}
const reactRootMap = new Map()

const renderElement = (
  component: ComponentTypes,
  domId: string,
  props?: object,
): void => {
  const Component: LazyExoticComponent<any> = COMPONENTS[component]
  renderComponent(reactRootMap, Component, domId, props)
}

export default {
  renderElement,
}
